.clearing-status {
  display: flex;

  .status-item {
    align-items: center;
    display: flex;
    flex: auto;

    .content {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .status-arrow {
    width: 15px;
  }
}